<template>
    <slot name="FormieSelect">
        <BaseSelect
            v-bind="$attrs"
            :deselect-sr-label="deselectSrLabel"
            :selected-sr-label="selectedSrLabel"
            :toggle-sr-label="toggleSrLabel"
        />
    </slot>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
    deselectSrLabel: {
        type: String,
        default: 'Deselecteer optie',
    },
    selectedSrLabel: {
        type: String,
        default: 'Geselecteerde optie',
    },
    toggleSrLabel: {
        type: String,
        default: 'Selecteer een optie',
    },
});

// Props to refs
const { deselectSrLabel, selectedSrLabel, toggleSrLabel } = toRefs(props);
</script>
