<template>
    <BaseFormField>
        <template #label>
            <slot name="label">
                <label v-bind="useField(field).getLabelBindings()">
                    {{ field.label }} <span v-if="field.required" aria-hidden="true">*</span> <!-- eslint-disable-line-->
                </label>
            </slot>
        </template>

        <template #description>
            <slot name="description">
                {{ field.instructions }}
            </slot>
        </template>

        <template #default>
            <slot name="default" />
        </template>
    </BaseFormField>
</template>

<script setup>
import { toRefs } from 'vue';
import { useField } from '../../composables/field';

const props = defineProps({
    field: {
        type: Object,
        default: () => {},
    },
});
// Props to refs
const { field } = toRefs(props);
</script>

<style scoped>
label[data-label-position='hidden'] {
    display: none;
}
</style>
