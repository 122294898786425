import revive_payload_client_4sVQNw7RlN from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/doen/multisite/doen-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/doen/multisite/doen-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/doen/multisite/doen-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/doen/multisite/doen-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/builds/doen/multisite/doen-frontend/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/builds/doen/multisite/doen-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/builds/doen/multisite/doen-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_u0Vr1vkWtR from "/builds/doen/multisite/doen-frontend/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/builds/doen/multisite/doen-frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import formie_zeDH0EkCFJ from "/builds/doen/multisite/doen-frontend/layers/craft/formie/plugins/formie.js";
import aos_client_Gr50AN4Ul1 from "/builds/doen/multisite/doen-frontend/plugins/aos.client.js";
import multisite_OwzkSRwj9b from "/builds/doen/multisite/doen-frontend/plugins/multisite.js";
import vue_leaflet_client_YETBDdCASL from "/builds/doen/multisite/doen-frontend/plugins/vue-leaflet.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_u0Vr1vkWtR,
  plugin_w2VlvAEcpa,
  formie_zeDH0EkCFJ,
  aos_client_Gr50AN4Ul1,
  multisite_OwzkSRwj9b,
  vue_leaflet_client_YETBDdCASL
]